body{
  font-family: Roboto;
}
#mainDiv{
  height: 98vh;
  color: #0EB1D2;
  display: grid;
  grid-template-rows: 49px auto auto auto auto auto 
}
.colorWhite{
  color:white !important;
}
.centerDiv{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.height{
  height: 100%;
}
#header{
  display:flex;
  align-items: center;
}
.fourButton{
  margin-top: 14px;
  margin-bottom: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-right: 49px;
  padding-left: 49px;
}
#button{
  margin: 10px;
  max-width: 350px;
  border-style: solid;
  border-radius: 15px;
  border-width: 0px;
  border-color: #0EB1D2;
  background-color: #2e3736;
  color: #0EB1D2;
  font-size: 200%;
  transition: color 0.5s ease;
}
#button:hover{
  background-color:#3e6990;
  color: #b2ffa9
}

.divider{
  padding-right: 49px; 
  padding-left: 49px;  
  height:20px;
  border-radius:50px;
  border-style: none;
  background-image:
    linear-gradient(
      white,silver, grey,silver, white
    );
}
.buttonPic{
  height: auto;
  width: 100%;
  margin: 15px;
}
