.Header {
  width: calc(100% - 20px);
  background-color: #2e3736;
  display: flex;
  align-items: center;
  padding-left: 2px;

  &-Logo {
    height: 49px;
  }

  & &-Button {
    font-size: 1.5em;
    color: white; 
    margin: 0px 10px;
  }
} 