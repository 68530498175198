body {
  background-color: #2e3736;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.Header {
  width: calc(100% - 20px);
  background-color: #2e3736;
  display: flex;
  align-items: center;
  padding-left: 2px; }
  .Header-Logo {
    height: 49px; }
  .Header .Header-Button {
    font-size: 1.5em;
    color: white;
    margin: 0px 10px; }

.Login {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: calc(98vh - 49px); }
  .Login-Name {
    font-family: Myriad Pro;
    font-size: 2em;
    font-weight: bold; }
  .Login-Logo {
    height: 50vh; }
  .Login-Message {
    font-size: 1.25em; }
  .Login-Form {
    width: 50vh; }
    .Login-Form div::after {
      border-bottom: 2px solid white !important; }
    .Login-Form button {
      background-color: #00A5CF; }
  .Login .Login-Field {
    margin-bottom: 1em; }
  .Login .Login-Button {
    margin-top: 1em; }

input {
  color: white !important; }

body{
  font-family: Roboto;
}
#mainDiv{
  height: 98vh;
  color: #0EB1D2;
  display: grid;
  grid-template-rows: 49px auto auto auto auto auto 
}
.colorWhite{
  color:white !important;
}
.centerDiv{
  display:flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.height{
  height: 100%;
}
#header{
  display:flex;
  align-items: center;
}
.fourButton{
  margin-top: 14px;
  margin-bottom: 14px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding-right: 49px;
  padding-left: 49px;
}
#button{
  margin: 10px;
  max-width: 350px;
  border-style: solid;
  border-radius: 15px;
  border-width: 0px;
  border-color: #0EB1D2;
  background-color: #2e3736;
  color: #0EB1D2;
  font-size: 200%;
  -webkit-transition: color 0.5s ease;
  transition: color 0.5s ease;
}
#button:hover{
  background-color:#3e6990;
  color: #b2ffa9
}

.divider{
  padding-right: 49px; 
  padding-left: 49px;  
  height:20px;
  border-radius:50px;
  border-style: none;
  background-image:
    -webkit-linear-gradient(
      white,silver, grey,silver, white
    );
  background-image:
    linear-gradient(
      white,silver, grey,silver, white
    );
}
.buttonPic{
  height: auto;
  width: 100%;
  margin: 15px;
}

